import config from '@/config/config';
import Axios from 'axios';

export default new class FaqsService {
    private config = config.getConfig();
    getToken() {
        return localStorage.getItem('__token');
    }

    async saveFaq(data: any) {
        // You should have a server side REST API 
        try {
            return (await Axios.post(`${this.config.apiUrl}/faqs`,
                data, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async updateFaq(id: string, data: any) {
        // You should have a server side REST API 
        try {
            return (await Axios.put(`${this.config.apiUrl}/faqs/${id}`,
                data, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async deleteFaq(id: string) {
        // You should have a server side REST API 
        try {
            return (await Axios.delete(`${this.config.apiUrl}/faqs/${id}`, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async getAllFaqs() {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/faqs`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async getFaq(id: string) {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/faqs/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
};

